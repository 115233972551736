import React, { useEffect, useState } from 'react';
import {
  ConfigProvider,
  Layout,
  Skeleton,
  Space,
  Typography
} from 'antd';

import './App.css';
import LoginForm from './components/LoginForm';
import Console from './components/Console';
import Authorize from './components/Authorize';
import UsersAPI from './api/user';
import AuthAPI from './api/auth';
import Cookies from 'universal-cookie';
import TicketsAPI from './api/ticket';


const { Text, Title } = Typography;

function App() {

  const [isLoading, setIsLoading] = useState(false);
  const [user, setUser] = useState(null);
  const [sessions, setSessions] = useState([
    // {
    //   session_id: 'xxx',
    //   app_icon: 'app_ChulaSSO.png',
    //   app_title: 'Chula SSO 2.0',
    //   last_access: 'xxx',
    //   first_access: 'xxx',
    //   valid_until: 'xxxx'
    // },
    // {
    //   session_id: 'yyy',
    //   app_icon: 'app_ChulaSSO.png',
    //   app_title: 'Chula SSO 2.0',
    //   last_access: 'yyy',
    //   first_access: 'yyy',
    //   valid_until: 'yyyy'
    // },
  ]);

  useEffect(() => {
    if (!user) {
      return;
    }
    TicketsAPI.search({},
      (res) => {
        setSessions(res);
      },
      (err) => {
        console.log(err);
      }
    )
    return () => {

    }
  }, [user]);


  const cookies = new Cookies();

  let service = null;
  try {
    const st_requestService = window.atob(cookies.get('SSO_requestService'));
    console.log('requestService', st_requestService);
    service = JSON.parse(st_requestService);
  } catch (e) {
    console.log(e);
  }

  const logout = () => {
    AuthAPI.logout(
      (res) => {
        setUser(null);
      },
      (err) => {
        console.log(err);
      });
  }

  const onLogin = () => {
    setIsLoading(true);
    UsersAPI.me(
      (user) => {
        setUser(user);
        setIsLoading(false);
      },
      (err) => {
        setUser(null);
        setIsLoading(false);
      })
  }

  useEffect(() => {

    onLogin();

    return () => {

    }
  }, []);


  return (
    <ConfigProvider theme={{ token: { colorPrimary: '#fc46aa' } }}>
      <Layout style={{ minHeight: '100vh' }}>
        <Layout.Header style={{
          height: 120,
          alignItems: 'center',
          backgroundColor: 'pink'
        }}>
          <div style={{ paddingTop: '5px' }}>
            <Space>
              <img src="logo-chula-color.svg" width={120} style={{ verticalAlign: 'middle' }} />
              <Title level={2}>SSO 2.0</Title>
            </Space>
          </div>

        </Layout.Header>
        <Layout.Content style={{ padding: '5px' }}>

          <Skeleton loading={isLoading} avatar >

            {!user && <LoginForm
              onLogin={onLogin} />}

            {user && <>

              {service && <Authorize
                user={user}
                service={service}
              />}

              <Console
                sessions={sessions}
                user={user}
                onLogout={logout}
              />
            </>}

          </Skeleton>

        </Layout.Content>
        <Layout.Footer>
          <address> Designed by Krerk Piromsopa, Ph.D.<br /> for Chulalongkorn University.</address>
          For more information, visit our <a href="/wiki/">wiki</a> page.
        </Layout.Footer>
      </Layout>
    </ConfigProvider >
  );
}

export default App;
