import React from 'react';
import {
    Button,
    Card,
    Checkbox,
    Form,
    Input,
    message,
} from 'antd';
import AuthAPI from '../api/auth';

export default function LoginForm(props) {
    const {
        onLogin
    } = props;
    const [messageApi, contextHolder] = message.useMessage();


    const onFinish = (values) => {
        const params = values;
        console.log(values);
        AuthAPI.login(params,
            (res) => {
                //console.log(res);
                messageApi.open({
                    type: 'success',
                    content: 'Welcome! You have been authenticated.',
                });
                onLogin(res);
            },
            (err) => {
                console.log(err);
                messageApi.open({
                    type: 'error',
                    content: 'Invalid username or password',
                });
            }
        )
    }

    const onFinishFailed = (values) => {
        console.log(values);
    }

    return <Card title="Login">
        <Form
            name="basic"
            labelCol={{
                span: 8,
            }}
            wrapperCol={{
                span: 16,
            }}
            style={{
                maxWidth: 600,
            }}
            initialValues={{
                remember: true,
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
        >
            {contextHolder}
            <Form.Item
                label="Username"
                name="username"
                rules={[
                    {
                        required: true,
                        message: 'Please input your username!',
                    },
                ]}
            >
                <Input />
            </Form.Item>

            <Form.Item
                label="Password"
                name="password"
                rules={[
                    {
                        required: true,
                        message: 'Please input your password!',
                    },
                ]}
            >
                <Input.Password />
            </Form.Item>

            {/* <Form.Item
                name="remember"
                valuePropName="checked"
                wrapperCol={{
                    offset: 8,
                    span: 16,
                }}
            >
                <Checkbox>Remember me</Checkbox>

            </Form.Item> */}

            <Form.Item
                wrapperCol={{
                    offset: 8,
                    span: 16,
                }}
            >
                <Button type="primary" htmlType="submit">
                    Submit
                </Button>
            </Form.Item>
        </Form>
    </Card>
}